import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {useEffect} from "react";
import {getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, getAdditionalUserInfo, sendSignInLinkToEmail} from "firebase/auth";
import AuthService from "../services/AuthService";

const Auth = () => {
    useEffect(() => {
        // FirebaseUI config.
        let uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    const currentUser = firebase.auth().currentUser;

                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    return true;
                },
                uiShown: function() {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                },

            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInSuccessUrl: '/mobiles',
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                },
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            // Other config options...
        }

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

        const auth = getAuth();

        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user by importing getAdditionalUserInfo
                    // and calling it with result:
                    console.log(getAdditionalUserInfo(result))

                    window.location.href = '/mobiles';
                })
                .catch(console.error);
        }


        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                new AuthService().setUser(user).catch(console.error)

                window.location.href = '/mobiles';
                // ...
            } else {
                // User is signed out
                // ...
                console.log("No user!")
            }
        });

        // Check if FirebaseUI instance already exists
        // if (ui.isPendingRedirect()) {
            ui.start('#firebaseui-auth-container', uiConfig);
        // }

        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            ui.start('#firebaseui-auth-container', uiConfig);
        }

        // Add logic to collect email from FirebaseUI-generated input field
        const firebaseUIContainer = document.getElementById('firebaseui-auth-container');
        if (firebaseUIContainer) {
            firebaseUIContainer.addEventListener('input', (event) => {
                if (event.target && event.target.type === 'email') {
                    const email = event.target.value;
                    if (email) {
                        window.localStorage.setItem('emailForSignIn', email);
                    }
                }
            });
        }

        // Cleanup function to prevent memory leaks
        return () => {
            ui.reset();
        };
    }, []);

    return (
        <div>
            <div id="firebaseui-auth-container"></div>
        </div>
    );
};

function Login() {
    return (
        <Auth/>
    );
}

export default Login;

import './App.css';
import Navbar from "./components/pages/Navbar";
import {createBrowserRouter, Link, NavLink, RouterProvider} from "react-router-dom";
import Login from "./components/pages/Login";
import Account from "./components/pages/Account";
import Mobile from "./components/pages/Mobile";
import Activate from "./components/pages/Activate";
import MobilePlan from "./components/pages/MobilePlan";
import Invoice from "./components/pages/Invoice";
import MobileSettings from "./components/pages/MobileSettings";
import Mobiles from "./components/pages/Mobiles";
import EventList from "./components/pages/EventList";
import Map from "./components/pages/Map";
import Commands from "./components/pages/Commands";
import Home from "./components/pages/Home";
import ScreenshotList from "./components/pages/ScreenshotList";
import AudioList from "./components/pages/AudioList";
import PhotoList from "./components/pages/PhotoList";

const router = createBrowserRouter([
    {
        element: <Navbar />,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "account",
                element: <Account/>,
            },
            {
                path: "details/:id",
                element: <MobilePlan/>,
            },
            {
                path: "activate/:id",
                element: <Activate/>,
            },
            {
                path: "login",
                element: <Login/>,
            },
            {
                path: "invoice/:id",
                element: <Invoice/>,
            },
            {
                path: "mobile/:id/settings",
                element: <MobileSettings/>,
            },
            {
                path: "mobile/:id/map",
                element: <Map/>,
            },
            {
                path: "mobile/:id/events",
                element: <EventList/>,
            },
            {
                path: "mobile/:id/screenshots",
                element: <ScreenshotList/>,
            },
            {
                path: "mobile/:id/audio",
                element: <AudioList/>,
            },
            {
                path: "mobile/:id/photos",
                element: <PhotoList/>,
            },
            {
                path: "mobiles",
                element: <Mobiles/>,
            },
            {
                path: "mobile/:id",
                element: <Mobile/>,
            },
            {
                path: "mobile/:id/commands",
                element: <Commands/>,
            },

        ]}
]);

function App() {
  return (
      <RouterProvider router={router}/>
  );
}


export default App;

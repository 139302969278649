import React, {useState, useEffect, useRef} from 'react';
import {NavLink, useParams} from "react-router-dom";
import useApiData from "../services/useApiData";
import InfiniteScroll from "react-infinite-scroll-component";
import MobileNavbar from "./MobileNavbar";
import ReactPlayer from "react-player";
import DatePicker from "react-datepicker";
import {DayPicker} from "react-day-picker";
import records from "../../models/Records";

function ImageRecord({ record }) {
    return <img src={record.url} alt={record.type} style={{ maxWidth: "100px" }} />;
}

function AudioRecord({ record }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <i className="bi bi-mic-fill" style={{ marginRight: "10px" }}/>
            <div className="audioplayer">
                <ReactPlayer url={record.url} controls={true}/>
            </div>
        </div>
    );
}

function SmsRecord({ record }) {
    return (
        <div>
            {record.address}: {record.body}
            {JSON.stringify(record)}
        </div>
    );
}

function CallRecord({ record }) {
    return (
        <div>
            {record.name}: {record.number}: {record.duration}
        </div>
    );
}

function generalRecord(record) {
    return (
        <div>
            {record.type}
        </div>
    );
}

function recordElement(record) {
    switch(record.type) {
        case 'photos':
        case 'screenshots':
            return <ImageRecord record={record} />
        case 'audio':
            return <AudioRecord record={record} />;
        case 'sms':
            return <SmsRecord record={record} />;
        case 'call':
            return <CallRecord record={record} />;
        default:
            return generalRecord(record);
    }
}

function Record({ record }) {
    return (
        <tr key={record.id}>
            <td>
                {record.occurredAt} <br/>
                {recordElement(record)}
                <br/>
                {new Date(record.occurredAt).toLocaleString()}<br/>
                {Math.round(record.sizeBytes * 10 / 1024 / 1024) / 10} MB<br/>
                {/*{record.extra} {record.type == 'notification' && JSON.stringify(record)}*/}
            </td>
        </tr>
    );
}

function EventTypeSelector(props) {
    const types = ["all", "audio", "screenshot", "picture"];

    return (
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
            {types.map((type, index) => (
                <React.Fragment key={index}>
                    <input type="radio" className="btn-check" name="btnradio" id={`btnradio${index}`} autoComplete="off"
                            value={type}
                            onChange={props.onChange}
                    />
                    <label className="btn btn-outline-primary" htmlFor={`btnradio${index}`}>{type}</label>
                </React.Fragment>
            ))}
        </div>
    );
}

function EventList() {
    const length = 30;
    const { id } = useParams();
    const [offset, setOffset] = useState(null); // Track the current offset
    const [allRecords, setAllRecords] = useState([]); // Track all loaded records
    const [hasMore, setHasMore] = useState(true); // Track if more data is available
    const [selectedDate, setSelected] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(true);
    const [selectedType, setSelectedType] = useState("all");

    // Fetch records with current offset
    const { data: results, loading: loadingRecords, error: errorRecords, loadData: fetchRecords } = useApiData(
        "getRecords",
        { mobileId: id, limit: length, offset: offset, type: selectedType }
    );

    const timestamp = results?.timestamp;

    useEffect(() => {
        if (results && results.records) {
            if (results.records.length < length) {
                setHasMore(false); // No more data to load if less than 20 records are returned
            }

            setAllRecords((prevRecords) => [...prevRecords, ...results.records]);
            const lastRecord = results.records[results.records.length - 1];

            if (lastRecord) {
                setOffset(lastRecord.occurredAt.toString());
            }
        }
    }, [timestamp]);

    const dateSelected = (date) => {
        if (!date) {
            return;
        }

        setSelected(date);
        setAllRecords([]);
        setNeedToLoad(Math.random());

        const offset = date.getTime().toString();

        fetchRecords({ mobileId: id, limit: length, offset: offset, type: selectedType });
    }

    if (errorRecords) {
        return <div>Error: {errorRecords?.message}</div>;
    }

    if (results?.result === "expired") {
        return <div>
            The plan is expired, please re-activate.
            <NavLink to={`/activate/${id}`}>Activate</NavLink>
        </div>;
    }

    return (
        <div>
            {selectedType}
            <button className="btn btn-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#dayPickerCollapse" aria-expanded="false" aria-controls="dayPickerCollapse">
                <i className="bi bi-calendar"></i> Select a date
            </button>
            <div className="collapse" id="dayPickerCollapse">
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={dateSelected}
                    footer={
                        ""
                    }
                />
            </div>

            <EventTypeSelector
                onChange={(e) => {
                    setSelectedType(e.target.value);
                    setAllRecords([]);
                    fetchRecords({ mobileId: id, limit: length, offset: offset, type: selectedType });
                }}
            />

            <InfiniteScroll
                dataLength={allRecords.length} // This is important to track scroll position and avoid calls to loadMore before the previous load is complete
                next={fetchRecords}
                hasMore={hasMore}
                loader={<h4>Loading more records...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>No more records to show</b>
                    </p>
                }
            >
                <table className="table">
                    <tbody>
                    {allRecords.map(record => <Record key={"record" + record.id} record={record}/>)}
                    </tbody>
                </table>
            </InfiniteScroll>

            <MobileNavbar id={id}/>
        </div>
    );
}

export default EventList;

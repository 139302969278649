import React, {useState, useEffect, useRef} from 'react';
import {NavLink, useParams} from "react-router-dom";
import useApiData from "../services/useApiData";
import InfiniteScroll from "react-infinite-scroll-component";
import MobileNavbar from "./MobileNavbar";
import {DayPicker} from "react-day-picker";

function RecordElement(record) {
    record = record.record;
    var extra = {};
    try {
        extra = JSON.parse(record.extra)
    } catch (e) {
        console.log(e);
    }

    const url = extra.extraUrl || record.url;

    return (
        <div className="card" style={{maxWidth: "72px", display: "inline-block", margin: "2px"}}>
            <a href={record.url} target="_blank"><img src={url} className="card-img-top" alt="" width="72px"/></a>
            <small>
            <p>
                {new Date(record.occurredAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                <br/>
                {extra.appName}
            </p>
            </small>
        </div>
    )
}

function ScreenshotList() {
    const eventType = 'screenshots'

    const length = 30;
    const { id } = useParams();
    const [offset, setOffset] = useState(null); // Track the current offset
    const [allRecords, setAllRecords] = useState([]); // Track all loaded records
    const [hasMore, setHasMore] = useState(true); // Track if more data is available
    const [selectedDate, setSelected] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(true);

    // Fetch records with current offset
    const { data: results, loading: loadingRecords, error: errorRecords, loadData: fetchRecords } = useApiData(
        "getRecords",
        { mobileId: id, limit: length, offset: offset, type: eventType }
    );

    const timestamp = results?.timestamp;

    useEffect(() => {
        if (results && results.records) {
            if (results.records.length < length) {
                setHasMore(false); // No more data to load if less than 20 records are returned
            }

            setAllRecords((prevRecords) => [...prevRecords, ...results.records]);
            const lastRecord = results.records[results.records.length - 1];

            if (lastRecord) {
                setOffset(lastRecord.occurredAt.toString());
            }
        }
    }, [timestamp]);

    const dateSelected = (date) => {
        if (!date) {
            return;
        }

        setSelected(date);
        setAllRecords([]);
        setNeedToLoad(Math.random());

        const offset = date.getTime().toString();

        fetchRecords({ mobileId: id, limit: length, offset: offset, type: eventType });
    }

    if (errorRecords) {
        return <div>Error: {errorRecords?.message}</div>;
    }

    if (results?.result === "expired") {
        return <div>
            The plan is expired, please re-activate.
            <NavLink to={`/activate/${id}`}>Activate</NavLink>
        </div>;
    }

    return (
        <div>
            <button className="btn btn-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#dayPickerCollapse" aria-expanded="false" aria-controls="dayPickerCollapse">
                <i className="bi bi-calendar"></i> Select a date
            </button>
            <div className="collapse" id="dayPickerCollapse">
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={dateSelected}
                    footer={
                        ""
                    }
                />
            </div>

            <InfiniteScroll
                dataLength={allRecords.length} // This is important to track scroll position and avoid calls to loadMore before the previous load is complete
                next={fetchRecords}
                hasMore={hasMore}
                loader={<h4>Loading more records...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>No more records to show</b>
                    </p>
                }
            >

                    {allRecords.map(record => <RecordElement key={"record" + record.id} record={record}/>)}

            </InfiniteScroll>

            <MobileNavbar id={id}/>
        </div>
    );
}

export default ScreenshotList;
